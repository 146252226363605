import React, { useEffect, useState } from 'react'
import { Widget } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css'

const IframeEmbed = ({ pageSection }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkMobile = () => {
        setIsMobile(window.innerWidth <= 768)
      }

      checkMobile()
      window.addEventListener('resize', checkMobile)

      return () => window.removeEventListener('resize', checkMobile)
    }
  }, [])
  const height = isMobile ? '1300px' : pageSection.height || '1300px'
  const width = isMobile ? '100%' : pageSection.width || '100%'

  if (pageSection.formName && pageSection.formId) {
    return <Widget id={pageSection.formId} style={{ width, height }} />
  }

  const url = pageSection.formId
    ? `https://form.typeform.com/to/${pageSection.formId}`
    : pageSection.defaultCellLayout.current

  return <iframe width={width} height={height} src={url}></iframe>
}
export default IframeEmbed
